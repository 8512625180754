<template>
  <div class="d-flex flex-row">
    <datepicker-wrapper
      :id="id"
      v-model="date"
      class="w-auto"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
      reset-button
      today-button
      size="sm"
      v-bind="$attrs"
      @input="dateSelected"
    />
    <timepicker-wrapper
      v-model="time"
      class="w-auto"
      size="sm"
      reset-button
      now-button
      no-close-button
      v-bind="$attrs"
      @input="timeSelected"
    />
  </div>
</template>

<script>
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import TimepickerWrapper from '@/components/TimepickerWrapper.vue';

function getDMY(date) {
  if (!date) {
    return '';
  }
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${date.getFullYear()}-${month}-${day}`;
}

function getHMS(date) {
  if (!date) {
    return '';
  }
  const hours = `0${date.getHours()}`.slice(-2);
  const minutes = `0${date.getMinutes()}`.slice(-2);
  const seconds = `0${date.getSeconds()}`.slice(-2);
  return `${hours}:${minutes}:${seconds}`;
}

export default {
  name: 'Datetimepicker',
  components: {
    DatepickerWrapper,
    TimepickerWrapper,
  },
  props: {
    id: { type: [Number, String], default: undefined },
    value: { type: Date, default: undefined },
  },
  data() {
    return {
      date: getDMY(this.value),
      time: getHMS(this.value),
    };
  },
  watch: {
    value() {
      this.date = getDMY(this.value);
      this.time = getHMS(this.value);
    },
  },
  methods: {
    dateSelected() {
      if (!this.date) {
        this.time = '';
      }
      this.$emit('inputDate', this.date);
      this.updateValue();
    },
    timeSelected() {
      this.$emit('inputTime', this.time);
      this.updateValue();
    },
    updateValue() {
      this.$emit('input', this.date ? new Date(`${this.date}T${this.time ? this.time : '00:00:00'}`) : undefined);
    },
  },
}
</script>
