<template>
  <div class="wrapper-main__content" :class="[isThemeHeader === 'true' ? 'white-color' : 'dark-color']">
    <div class="form-content pb-4" style="padding-top: 10px">
      <VocabularyTitle class="mb-0" :title="$t('sideBar.logs')" :entries="paginationData?.total ?? null" />

      <div class="d-flex flex-wrap gap-2 mb-2">
        <MultiSelect
          id="filter-log-type"
          v-model="filters.log_type"
          class="multiselect-sm w-150px reports-select"
          :options="log_types"
          label="name"
          track-by="id"
          :allow-empty="false"
          @input="fetchLogs()"
        ></MultiSelect>
        <b-input-group
          size="sm"
          class="w-auto"
        >
          <b-form-input
            v-model="filters.request_id"
            :placeholder="$t('log.filter-request-id')"
            @input="filtersChanged = true"
          ></b-form-input>
          <b-input-group-append v-if="filters.request_id">
            <b-button @click="filters.request_id = ''; filtersChanged = true"><b-icon-backspace /></b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group
          size="sm"
          class="w-auto"
          >
          <b-form-input
            v-model="filters.user"
            :placeholder="$t('user.label')"
            @input="filtersChanged = true"
            />
          <b-input-group-append>
            <b-form-select
              v-model="filters.user_field"
              size="sm"
              :options="user_fields"
              @input="filtersChanged = true"
            />
          </b-input-group-append>
        </b-input-group>
        <b-button
          size="sm"
          :disabled="!filtersChanged"
          @click="fetchLogs"
        >
          {{ $t('log.filter-apply') }}
        </b-button>
        <b-button
          size="sm"
          :disabled="!filtersChanged"
          @click="resetFilters"
        >
          {{ $t('log.filter-reset') }}
        </b-button>
      </div>
      <div class="d-flex flex-wrap gap-2 mb-2">
        <b-form-group
          :label="$t('log.filter-timestamp-from')"
          label-for="filter-date-from"
          label-size="sm"
          label-class="py-0 mb-0"
        >
          <datetimepicker
            id="filter-date-from"
            v-model="filters.date_from"
            size="sm"
            :date-info-fn="dateClass"
            @input="dateFromSelected"
            @inputDate="dateFromStr"
          />
        </b-form-group>
        <b-form-group
          class="mb-0"
          :label="$t('log.filter-timestamp-to')"
          label-for="filter-date-to"
          label-size="sm"
          label-class="py-0 mb-0"
        >
          <datetimepicker
            id="filter-date-to"
            v-model="filters.date_to"
            size="sm"
            :date-info-fn="dateClass"
            @input="dateToSelected"
            @inputDate="dateToStr"
          />
        </b-form-group>
        <b-form-group
          v-if="isFilterEnabled('spot_id')"
          class="mb-0"
          :label="$t('log.filter-spot-id')"
          label-for="filter-spot-id"
          label-size="sm"
          label-class="py-0 mb-0"
        >
          <b-form-input
            id="filter-spot-id"
            v-model="filters.spot_id"
            :placeholder="$t('log.filter-spot-id')"
            size="sm"
            @input="filtersChanged = true"
          />
        </b-form-group>
        <b-form-group
          v-if="isFilterEnabled('block_id')"
          class="mb-0"
          :label="$t('log.filter-block-id')"
          label-for="filter-block-id"
          label-size="sm"
          label-class="py-0 mb-0"
        >
          <b-form-input
            id="filter-block-id"
            v-model="filters.block_id"
            :placeholder="$t('log.filter-block-id')"
            size="sm"
            @input="filtersChanged = true"
          />
        </b-form-group>
      </div>
      <div class="table-responsive">
        <table class="table table-hover" style="white-space: nowrap">
          <thead>
            <th
              v-if="!filters.log_type?.id"
              scope="col"
            >
              {{ $t('log.header-type') }}
            </th>
            <th
              v-if="visibleColumns.id"
              scope="col"
            >
              <div class="resizable-div__name">
                {{ $t('log.header-id') }}
              </div>
            </th>
            <th scope="col">
              <div class="resizable-div__name">
                {{ $t('log.header-request-id') }}
              </div>
            </th>
            <th scope="col">
              <div class="resizable-div__name">
                {{ $t('log.header-timestamp') }}
              </div>
            </th>
            <th scope="col">{{ $t('log.header-user') }}</th>
            <th
              v-if="filters.log_type?.id === 'spot-add'"
              scope="col"
            >
              {{ $t('log.header-spot-id') }}
            </th>
            <th
              v-if="filters.log_type?.id === 'spot-add'"
              scope="col"
            >
              {{ $t('log.header-block-id') }}
            </th>
            <th
              v-if="filters.log_type?.id === 'spot-add'"
              scope="col"
            >
              {{ $t('log.header-method') }}
            </th>
          </thead>
          <tbody>
            <tr v-if="logsListStatus !== 'success'">
              <td colspan="10"><SpinnerLoader :loading="logsListStatus" /></td>
            </tr>
            <tr
              v-for="row in tableData"
              :key="row.id"
              class="cursor-pointer"
              @click="showDetails(row)"
            >
              <td v-if="!filters.log_type?.id">{{ $t(`log.type-${row.type}`) }}</td>
              <td v-if="visibleColumns.id">{{ row.id }}</td>
              <td>{{ row.request_id }}</td>
              <td>{{ (new Date(row.timestamp))?.toLocaleString() }}</td>
              <td>
                <b-link
                  :disabled="!row.user"
                  @click.stop="showUserDetails(row.user)"
                >
                  {{ row.user?.name ?? row.user?.login ?? row.user?.email }}
                </b-link>
              </td>
              <td v-if="filters.log_type?.id === 'spot-add'">{{ row.spot_id }}</td>
              <td v-if="filters.log_type?.id === 'spot-add'">{{ row.block_id }}</td>
              <td v-if="filters.log_type?.id === 'spot-add'">{{ row.method }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="paginationData?.next_page_url"
        class="d-flex flex-row-reverse"
      >
        <b-button
          :disabled="fetchingNextLogs"
          @click="fetchNextLogs"
        >
          {{ $t('log.load-next-page', { per_page: paginationData?.per_page }) }}
        </b-button>
      </div>
    </div>
    <b-modal
      id="user-details-modal"
      :title="$t('log.header-user')"
      ok-only
    >
      <table>
        <tr v-if="detailsUser?.id">
          <td><strong>{{ this.$t('user.id') }}</strong></td>
          <td class="px-2">
            <b-link
              :to="{ path: '/admin/users', query: { search_type: 'id', q: detailsUser?.id } }"
            >
              {{ detailsUser?.id }}
            </b-link>
          </td>
        </tr>
        <tr v-if="detailsUser?.name">
          <td><strong>{{ this.$t('user.name') }}</strong></td>
          <td class="px-2">
            <b-link
              :to="{ path: '/admin/users', query: { search_type: 'name', q: detailsUser?.name } }"
            >
              {{ detailsUser?.name }}
            </b-link>
          </td>
        </tr>
        <tr v-if="detailsUser?.login">
          <td><strong>{{ this.$t('user.login') }}</strong></td>
          <td class="px-2">
            <b-link
              :to="{ path: '/admin/users', query: { search_type: 'login', q: detailsUser?.login } }"
            >
              {{ detailsUser?.login }}
            </b-link>
          </td>
        </tr>
        <tr v-if="detailsUser?.email">
          <td><strong>{{ this.$t('user.email') }}</strong></td>
          <td class="px-2">
            <b-link
              :to="{ path: '/admin/users', query: { search_type: 'email', q: detailsUser?.email } }"
            >
              {{ detailsUser?.email }}
            </b-link>
          </td>
        </tr>
      </table>
    </b-modal>
    <b-modal
      id="details-modal"
      :title="details?.request_id"
      ok-only
    >
      <table>
        <tr v-if="details?.timestamp">
          <td><strong>{{ this.$t('log.header-timestamp') }}</strong></td>
          <td class="px-2">{{ details?.timestamp }}</td>
        </tr>
        <tr v-if="details?.spot_id">
          <td><strong>{{ this.$t('log.header-spot-id') }}</strong></td>
          <td class="px-2">{{ details?.spot_id }}</td>
        </tr>
        <tr v-if="details?.block_id">
          <td><strong>{{ this.$t('log.header-block-id') }}</strong></td>
          <td class="px-2">{{ details?.block_id }}</td>
        </tr>
        <tr v-if="details?.details?.mediaplan_id">
          <td><strong>{{ this.$t('log.header-mediaplan-id') }}</strong></td>
          <td class="px-2">{{ details?.details?.mediaplan_id }}</td>
        </tr>
        <tr v-if="details?.details?.mediaplan_name">
          <td><strong>{{ this.$t('log.header-mediaplan-name') }}</strong></td>
          <td class="px-2">{{ details?.details?.mediaplan_name }}</td>
        </tr>
        <tr v-if="details?.details?.commercial_id">
          <td><strong>{{ this.$t('log.header-commercial-id') }}</strong></td>
          <td class="px-2">
            <b-link
              :to="{ path: '/admin/commercials', query: { q: details?.details?.commercial_id, search_type: 'id' } }"
            >
              {{ details?.details?.commercial_id }}
            </b-link>
          </td>
        </tr>
        <tr v-if="details?.details?.commercial_name">
          <td><strong>{{ this.$t('log.header-commercial-name') }}</strong></td>
          <td class="px-2">{{ details?.details?.commercial_name }}</td>
        </tr>
        <tr v-if="details?.details?.position">
          <td><strong>{{ this.$t('log.header-position') }}</strong></td>
          <td class="px-2">{{ details?.details?.position }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datetimepicker from '@/components/Datetimepicker.vue';
import MultiSelect from '@/components/MultiSelect';
import SpinnerLoader from '@/components/SpinnerLoader';
import VocabularyTitle from '@/components/VocabularyTitle';
import { BIconBackspace, BLink } from 'bootstrap-vue';

function dateToTimestamp(date) {
  return Math.floor(date.getTime() / 1000);
}

export default {
  name: 'Logs',
  components: {
    BIconBackspace,
    BLink,
    Datetimepicker,
    MultiSelect,
    SpinnerLoader,
    VocabularyTitle,
  },
  props: {
    per_page: { type: [String, Number], default: undefined },
    type: { type: String, default: undefined },
    ts_from: { type: Number, default: undefined },
    ts_to: { type: Number, default: undefined },
    request_id: { type: String, default: '' },
    user_id: { type: Number, default: undefined },
    user_login: { type: String, default: '' },
    user_name: { type: String, default: '' },
    user_email: { type: String, default: '' },
    spot_id: { type: Number, default: undefined },
    block_id: { type: Number, default: undefined },
  },
  data() {
    return {
      log_types: [
        { id: null, name: this.$t('log.type-all') },
        { id: 'spot-add', name: this.$t('log.type-spot-add') },
        { id: 'automatic', name: this.$t('log.type-automatic') },
      ],
      user_fields: [
        { value: 'user_login', text: this.$t('user.login') },
        { value: 'user_name', text: this.$t('user.name') },
        { value: 'user_id', text: this.$t('user.id') },
        { value: 'user_email', text: this.$t('user.email') },
      ],
      tableData: [],
      paginationData: null,
      visibleColumns: {
        id: false,
      },
      filters: {
        log_type: '',
        request_id: '',
        date_from: undefined,
        date_to: undefined,
        date_from_str: '',
        date_to_str: '',
        user_field: 'user_login',
        user: '',
        spot_id: '',
        block_id: '',
      },
      filtersChanged: false,
      filtersEnabled: {
        'spot-add': {
          'spot_id': true,
          'block_id': true,
        },
      },
      initialFilters: {},
      latestId: '',
      detailsUser: null,
      details: null,
      fetchingNextLogs: false,
    }
  },
  // TODO: validations
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      logsList: 'getLogsList',
      logsListStatus: 'getLogsListStatus',
      logData: 'getLogData',
      logDataStatus: 'getLogDataStatus',
      users: 'getUsers',
    }),
  },
  watch: {
    logsList() {
      this.loadLogs();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.logs') + ' – OpenMediaLogic';
  },
  beforeMount() {
    this.filters.log_type = this.log_types.find(t => t?.id === (this.type ?? null));
    this.filters.request_id = this.request_id ?? '';
    this.filters.date_from = this.ts_from ? new Date(this.ts_from * 1000) : undefined;
    this.filters.date_to = this.ts_to ? new Date(this.ts_to * 1000) : undefined;
    this.filters.spot_id = this.spot_id ?? '';
    this.filters.block_id = this.block_id ?? '';

    for (const key of ['user_id', 'user_login', 'user_name', 'user_email']) {
      if (this[key]) {
        this.filters.user = this[key];
        this.filters.user_field = key;
        break;
      }
    }

    this.fetchLogs();
  },
  methods: {
    filtersToQuery(filters) {
      const query = { ...this.$route.query };
      if (filters.log_type?.id) {
        query.type = filters.log_type?.id;
      } else {
        delete query.type;
      }
      if (filters.date_from) {
        query.ts_from = dateToTimestamp(filters.date_from);
      } else {
        delete query.ts_from;
      }
      if (filters.date_to) {
        query.ts_to = dateToTimestamp(filters.date_to);
      } else {
        delete query.ts_to;
      }
      const fields = ['user_id', 'user_login', 'user_name', 'user_email'];
      if (filters.user) {
        for (const field of fields) {
          const user = filters.user?.toString().trim() ?? '';
          if (field === filters.user_field && user) {
            query[field] = user;
          } else {
            delete query[field];
          }
        }
      } else {
        for (const field of fields) {
          delete query[field];
        }
      }
      for (const filter of ['request_id', 'spot_id', 'block_id']) {
        const value = filters[filter]?.toString().trim() ?? '';
        if (value) {
          query[filter] = value;
        } else {
          delete query[filter];
        }
      }
      return query;
    },
    replaceQuery(query) {
      this.$router.replace({ query }).catch(e => {
        if (e.name !== 'NavigationDuplicated') {
          throw e;
        }
      });
    },
    queryToFormData(query) {
      const formData = {};
      for (const filter in query) {
        formData[`filter[${filter}]`] = typeof(query[filter]) === 'string' ? query[filter].trim() : query[filter];
      }
      return formData;
    },
    async fetchLogs() {
      const query = this.filtersToQuery(this.filters);
      this.replaceQuery(query);
      const formData = this.queryToFormData(query);
      this.tableData = this.paginationData = '';
      await this.$store.dispatch('GET_LOGS', { formData, handler: () => {
        this.filtersChanged = false;
        this.initialFilters = { ...this.filters };
      }})
    },
    async fetchNextLogs() {
      const query = this.filtersToQuery(this.initialFilters);
      const next_page_url = this.paginationData?.next_page_url;
      const formData = this.queryToFormData(query);
      this.fetchingNextLogs = true;
      const handler = () => { console.log('hello'); this.fetchingNextLogs = false };
      await this.$store.dispatch('GET_NEXT_LOGS', { formData, next_page_url, handler, handlerError: handler })
    },
    loadLogs() {
      if (this.logsList) {
        this.latestId = this.logsList.data[0]?.id;
        this.tableData = this.logsList.data;
        // this.tableData = this.logsList.data?.map((item) => {
        //   item.timestamp = new Date(item.timestamp);
        //   return item;
        // });
        this.paginationData = this.logsList.pagination;
      }
    },
    dateFromSelected() {
      if (this.filters.date_to && this.filters.date_to < this.filters.date_from) {
        this.filters.date_to = this.filters.date_from;
        this.filters.date_to_str = this.filters.date_from_str;
      }
      this.filtersChanged = true;
    },
    dateToSelected() {
      if (this.filters.date_from && this.filters.date_to < this.filters.date_from) {
        this.filters.date_from = this.filters.date_to;
        this.filters.date_from_str = this.filters.date_to_str;
      }
      this.filtersChanged = true;
    },
    dateFromStr(value) {
      this.filters.date_from_str = value;
    },
    dateToStr(value) {
      this.filters.date_to_str = value;
    },
    dateClass(ymd) {
      const classStr = [];
      if ((this.filters.date_from_str && this.filters.date_to_str
        && this.filters.date_from_str < ymd && ymd < this.filters.date_to_str)
        || (this.filters.date_from_str == ymd || this.filters.date_to_str == ymd)) {
        classStr.push('table-active')
      }
      return classStr;
    },
    showUserDetails(user) {
      this.detailsUser = user;
      this.$bvModal.show('user-details-modal');
    },
    showDetails(log) {
      this.details = log;
      if (this.details) {
        this.$bvModal.show('details-modal');
      }
    },
    isFilterEnabled(name) {
      const filters = this.filtersEnabled[this.filters.log_type?.id];
      return filters ? filters[name] : false;
    },
    resetFilters() {
      this.filters = this.initialFilters;
      this.filtersChanged = false;
    },
  },
};
</script>

<style>
a.disabled {
  pointer-events: none;
}
</style>
