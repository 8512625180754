<template>
  <b-input-group>
    <b-form-input
      :id="id"
      ref="input"
      :value="value"
      type="time"
      step="1"
      v-bind="$attrs"
      @input="updateValue"
    ></b-form-input>
    <b-input-group-append>
      <b-form-timepicker
        :value="value"
        button-only
        right
        show-seconds
        :locale="isLocale"
        v-bind="$attrs"
        :label-no-time-selected="$t('timepicker.no-time-selected')"
        :label-increment="$t('timepicker.increment')"
        :label-decrement="$t('timepicker.decrement')"
        :label-hours="$t('timepicker.hours')"
        :label-minutes="$t('timepicker.minutes')"
        :label-seconds="$t('timepicker.seconds')"
        :label-now-button="$t('timepicker.now')"
        :label-reset-button="$t('timepicker.reset')"
        :label-close-button="$t('timepicker.close')"
        :hour12="false"
        @input="updateValue"
      ></b-form-timepicker>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormTimepicker } from 'bootstrap-vue';

export default {
  name: 'TimepickerWrapper',
  components: { BFormTimepicker },
  props: {
    // for proper work in form-group
    id: { type: [Number, String], default: undefined },
    value: { type: String, default: '' },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
    }),
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    }
  },
}
</script>
